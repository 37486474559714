"use client";

import { useEffect, useRef } from "react";
export const InlineVideo = ({
  src = "",
  className = "",
  ...props
}) => {
  const observerRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (!observerRef.current) return;
        if (entry.isIntersecting) {
          observerRef.current?.play();
        } else {
          observerRef.current?.pause();
        }
      });
    }, {
      threshold: 0.15
    });
    if (observerRef.current) {
      observer.observe(observerRef.current);
    }
    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, []);
  return <video ref={observerRef} className={className} autoPlay loop muted playsInline src={src} {...props} data-sentry-component="InlineVideo" data-sentry-source-file="InlineVideo.tsx" />;
};